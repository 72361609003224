<script setup lang="ts">
const { wide } = useAttrs();
</script>

<template>
  <UContainer
    class="py-m w-full overflow-y-scroll"
    :ui="{ constrained: wide ? 'max-w-7xl' : 'max-w-3xl' }"
  >
    <slot />
  </UContainer>
</template>
